import { useDomainContext } from "@hooks/useDomainContext";
import React, { ReactElement } from "react";
import styles from "./legal.module.scss";
import TermsAndConditionsFallback from "./fallbacks/termsAndConditionsFallback";
export default function TermsAndConditions({
    domainNameAsProp,
    categoryNameAsProp = "Auto Warranty and Car insurance",
    className,
    mainWrapperClassName,
}: {
    domainNameAsProp?: string;
    categoryNameAsProp?: string;
    className?: string;
    mainWrapperClassName?: string;
}): ReactElement {
    const {
        domain: { name: actualDomainName, niceName, textBlocks },
        category,
    } = useDomainContext();
    const domainName = niceName ?? actualDomainName ?? domainNameAsProp;
    const categoryName = category?.name ?? categoryNameAsProp;
    const replaceDomainName = (text: string) => {
        return text
            .replace(/{domainName}/g, domainName)
            ?.replace(/{categoryName}/g, categoryName)
            ?.replace(/{mailInfo}/g, domainName.toLowerCase());
    };
    const domainTermsAndConditions = textBlocks?.find(
        (textBlock) =>
            textBlock.position === "custom-domain-terms-and-conditions",
    )?.text;
    const categoryTermsAndConditions = textBlocks?.find(
        (textBlock) =>
            textBlock.position === "custom-category-terms-and-conditions",
    )?.text;
    return domainTermsAndConditions || categoryTermsAndConditions ? (
        <div
            className={`${mainWrapperClassName ?? styles["legal"]} ${
                className ?? ""
            }`}
            dangerouslySetInnerHTML={{
                __html: replaceDomainName(
                    (domainTermsAndConditions ??
                        categoryTermsAndConditions) as string,
                ),
            }}
        />
    ) : (
        <TermsAndConditionsFallback
            domainName={domainName}
            categoryName={categoryName}
            className={className}
            mainWrapperClassName={mainWrapperClassName}
        />
    );
}
