import React, { ReactElement } from "react";
import styles from "../legal.module.scss";

export default function TermsAndConditionsFallback({
    domainName,
    categoryName,
    className,
    mainWrapperClassName,
}: {
    domainName: string;
    categoryName: string;
    className?: string;
    mainWrapperClassName?: string;
}): ReactElement {
    return (
        <div
            className={`${mainWrapperClassName ?? styles["legal"]} ${
                className ?? ""
            }`}
        >
            <section>
                <p>
                    To enhance the user experience on {domainName}, we collect
                    data about the visitor device including operating system,
                    web browser, java script enabling status, IP address, and
                    referral URL. We may use this data to re-market our website
                    to those visitors by launching advertising campaigns on
                    reputable advertising companies. By accessing {domainName},
                    we assume you accept these terms and conditions. Do not
                    continue to use {domainName} if you do not agree to take all
                    of the terms and conditions stated on this page.
                </p>
            </section>
            <section>
                <h2>Privacy Policy</h2>
                <p>
                    Registration data and other personal information about you
                    collected on the Website, which may include personally
                    identifiable information you voluntarily submit, are subject
                    to the Website's Privacy Policy.
                </p>
                <p>
                    The Privacy Policy can be found at {domainName}
                    /privacy-policy.
                </p>
                <p>
                    Consent: By using our Website, {domainName}, you consent to
                    the collection and use of these information in accordance
                    with the Privacy Policy.
                </p>
            </section>
            <section>
                <h2>The Website “{domainName}”</h2>
                <p>
                    The Website is designed to help you locate information about{" "}
                    {categoryName}. The Website also connects you with
                    contractors and other providers who may be able to assist
                    you with your {categoryName}.
                </p>
            </section>
            <section>
                <h2>Use of the Website “{domainName}”</h2>
                <ul className="list-inside list-disc">
                    <li>
                        You can use the Website for your individual personal use
                        and you agree that you will not re-produce, duplicate,
                        use, trade or otherwise exploit the Website for any
                        commercial or non-personal purposes.
                    </li>
                    <li>
                        You agree to access the Website through the interface
                        provided by the Website using a standard web browser.
                    </li>
                    <li>
                        You may not decompile, reverse engineer, disassemble,
                        rent, lease, loan, sell, sublicense, or create
                        derivative works from the Website or its content. Nor
                        may you use any network monitoring, discovery software
                        or other programs or methods to determine the Website
                        architecture or to extract content from the Website or
                        information about its usage or users.
                    </li>
                </ul>
            </section>
            <section>
                <h2>Intellectual Property</h2>
                <p>
                    We own and retain all intellectual property aspects in the
                    Website, {domainName}
                </p>
            </section>
            <section>
                <h2>Copyright Infringement</h2>
                <p>
                    If you are a copyright owner or agent thereof and believe
                    that content on the Website infringes upon your copyright,
                    please submit notice, pursuant to the Copyright Protection
                    Law of the defending party's jurisdiction to our Copyright
                    Agent with the following information:
                </p>
                <ul className="list-inside list-disc">
                    <li>
                        An electronic or physical signature of the person
                        authorized to act on behalf of the owner of the
                        copyright;
                    </li>
                    <li>
                        A description of the copyrighted work that you claim has
                        been infringed;
                    </li>
                    <li>
                        The URL of the location containing the material that you
                        claim is infringing;
                    </li>
                    <li>Your address, telephone number, and email address;</li>
                    <li>
                        A statement by you that you have a good faith belief
                        that the disputed use is not authorized by the copyright
                        owner, its agent, or the law;
                    </li>
                    <li>
                        A statement by you, made under penalty of perjury, that
                        the above information in your notice is accurate and
                        that you are the copyright owner or authorized to act on
                        the copyright owner's behalf.
                    </li>
                </ul>
                <p>
                    Our Copyright Agent can be reached By email:{" "}
                    <a href={`mailto:info@${domainName?.toLowerCase()}`}>
                        info@{domainName}
                    </a>
                </p>
            </section>
            <section>
                <h2>Modification or Termination of Services</h2>
                <p>
                    You acknowledge and agree that we may modify or terminate
                    the services provided on the Website and any of its features
                    at any time or terminate your access to them without notice
                    to you. You acknowledge and agree that we shall not be
                    liable to you or any third party as a result of any such
                    modification or termination. You may discontinue use of the
                    Website at any time without notice to us.
                </p>
            </section>
            <section>
                <h2>Links to Other Websites</h2>
                <p>
                    Some information provided to you on our Website may contain
                    links to information that resides on third-party websites
                    not owned or controlled by us. Unless the link is to an
                    article or content on another website owned by us, we do not
                    claim ownership to the content on other websites you reach
                    by clicking on a link from the Website. Your rights to such
                    content will be governed by the terms of those third-party
                    websites. You acknowledge and agree that we are not
                    responsible for any third-party content and that you must
                    evaluate any such third-party content and bear all risks
                    associated with any use of the content, including any
                    reliance on the accuracy or completeness of the information.
                </p>
            </section>
            <section>
                <h2>Limitation of Liability</h2>
                <p>
                    In no event shall ${domainName}, or its directors, officers,
                    partners, employees, representatives, affiliates, or assigns
                    ("the {domainName} parties") be liable to you for your
                    accessing of this website or under or related to this
                    agreement for any indirect, special, punitive, incidental,
                    reliance, exemplary or consequential damages, loss of
                    profits, loss of expected savings, or any other non-direct
                    damages howsoever caused whether or not {domainName} or any
                    of the {domainName} parties have been advised of the
                    possibility of such damages. {domainName}'s and the{" "}
                    {domainName} parties' collective maximum liability to you
                    under or related to this agreement shall in no event exceed
                    the amount paid by you for use of the website or services
                    provided on the website, if any. The limitations of
                    liability and disclaimers in this agreement shall apply
                    regardless of the form of action, whether in contract,
                    warranty, strict liability, negligence or other tort and
                    shall survive a fundamental breach or breaches or the
                    failure of the essential purpose of contract or the failure
                    of an exclusive remedy. Because some jurisdictions do not
                    allow the exclusion or limitation of incidental or
                    consequential damages, {domainName}'s and the {domainName}{" "}
                    parties' liability in such jurisdictions shall be limited to
                    the fullest extent permitted by law.
                </p>
            </section>
            <section>
                <h2>Disclaimer and Indemnity</h2>
                <p>
                    You agree to defend, indemnify and hold harmless{" "}
                    {domainName} and its directors, officers, partners,
                    employees, representatives, contractors, affiliates,
                    successors or assigns, including all third parties mentioned
                    on the Website, from and against any and all claims, actions
                    or demands, including without limitation reasonable legal
                    and accounting fees, alleging or resulting from your use of
                    the Website or the services and the content thereon or your
                    breach of this Agreement.
                </p>
            </section>
            <section>
                <h2>Statute of Limitations Disclaimer</h2>
                <p>
                    You agree that regardless of any statute or law to the
                    contrary, any claim or cause of action arising out of or
                    related to use of the Website or the Agreement must be filed
                    within one (1) year after such claim or cause of action
                    arose or be forever barred.
                </p>
            </section>
            <section>
                <h2>Choice of Law</h2>
                <p>
                    This Agreement, {domainName} Terms and Conditions, shall be
                    governed by and construed under the laws of the defending
                    party's jurisdiction without giving effect to its conflict
                    of laws principles. You and {domainName} hereby submit to
                    the exclusive forum, jurisdiction and venue of the courts
                    located in defending party's jurisdiction for any claim
                    related to, arising from or in connection with this
                    Agreement and/or your use of the Website.
                </p>
            </section>
            <section>
                <h2>Cookies</h2>
                <p>
                    The websites might employ the use of cookies. By accessing
                    Website Name, {domainName}, you agreed to use cookies in
                    agreement.
                </p>
                <p>
                    Most interactive websites use cookies to let us retrieve the
                    user's details for each visit. Cookies are used by our
                    website to enable the functionality of certain areas to make
                    it easier for people visiting our website. Some of our
                    affiliate/advertising partners may also use cookies.
                </p>
            </section>
            <section>
                <h2>iFrames/Inline Frame</h2>
                <p>
                    Without prior approval and written permission, you may not
                    create frames around our Webpages that alter in any way the
                    visual presentation or appearance of our Website,{" "}
                    {domainName}.
                </p>
            </section>
            <section>
                <h2>Disclaimers</h2>
                <p>
                    All content and services provided on or through this website
                    are provided "as is" and "as available" for your use. The
                    content is provided without warranties or conditions of any
                    kind, either expressed or implied, including but not limited
                    to implied warranties and conditions of merchantability,
                    fitness for a particular purpose or non-infringement.
                    Neither {domainName}, its affiliates and related entities,
                    nor any of their respective employees, agents, third party
                    content providers or licensors warrant that: the website or
                    content thereon will be up-to-date, complete, comprehensive,
                    accurate or applicable to your circumstances; the quality of
                    any products, services, information, or other material
                    obtained by you through the website will meet your
                    expectations; this website will be available at any
                    particular time or location; or any defects or errors will
                    be corrected or that the website is free of viruses or other
                    harmful components. Your use of this website is solely at
                    your own risk.
                </p>
                <p>
                    To the maximum extent permitted by applicable law, we
                    exclude all representations, warranties and conditions
                    relating to our website and the use of this website. Nothing
                    in this disclaimer will:
                </p>
                <ul className="list-inside list-disc">
                    <li>
                        Limit or exclude our or your liability for death or
                        personal injury;
                    </li>
                    <li>
                        Limit or exclude our or your liability for fraud or
                        fraudulent misrepresentation;
                    </li>
                    <li>
                        Limit any of our or your liabilities in any way that is
                        not permitted under applicable law; or
                    </li>
                    <li>
                        Exclude any of our or your liabilities that may not be
                        excluded under applicable law.
                    </li>
                </ul>
                <p>
                    The limitations and prohibitions of liability set in this
                    Section and elsewhere in this disclaimer: (a) are subject to
                    the preceding paragraph; and (b) govern all liabilities
                    arising under the disclaimer, including liabilities arising
                    in contract, in tort and for breach of statutory duty.
                </p>
                <p>
                    As long as the website and the information and services on
                    the website are provided free of charge, we will not be
                    liable for any loss or damage of any nature.
                </p>
            </section>
        </div>
    );
}
